/**
* Clips text with an ellipsis (single line).
*/
@mixin truncate-ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/**
* Clips text with an ellipsis (when more than $max-lines).
*/
@mixin truncate-ellipsis-max-lines($max-lines: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $max-lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
