input {
    box-shadow: unset; // Otherwise, Firefox renders a red shadow around required fields
}
.ng-invalid.ng-dirty {
    border-bottom-color: var(--error-color);
}
.mat-form-field-appearance-legacy .mat-hint,
.mat-hint {
    &.alert {
        font-size: 0.8rem;
    }
    &.alert-danger {
        color: var(--error-color);
    }
}
