@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/material-symbols-outlined.woff2') format('woff2');
}

// Variable icon font is > 3 MB in size. Therefore, we only use a subset including
// the filled/outlined and weight from 200-700 icons.
// Download link (can be customized) here: https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght,FILL@200..700,0..1
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-variation-settings: 'FILL' 1;
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &.outlined {
        font-variation-settings: 'FILL' 0;
    }

    &.icon-emphasized {
        // Some Material Symbols might be relatively small/thin compared to previous Material Icons
        // Increase weight to make them bigger/thicker
        font-variation-settings:
            'FILL' 1,
            'wght' 700;
    }

    &.icon-light {
        // Some Material Symbols might be relatively large/thick compared to previous Material Icons
        // Decrease weight to make them smaller/thinner
        font-variation-settings:
            'FILL' 1,
            'wght' 300;
    }
}
