@use '../node_modules/@angular/material/index' as mat;
@use 'ax-material-theme' as axTheme;
@use 'sass:map';
@import '../node_modules/@angular/material/theming';
@import 'responsive-breakpoints';

// Include non-theme styles for core
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

//*****************************************************************************
//  autoiXpert Theme (blue)
//****************************************************************************/
// Define a theme.
$typographyConfig: mat.define-typography-config();

// Create our own theme based on aX colors. We currently don't have two
// separate colors for primary and accent. That's why we use the same palette
// for both.
$theme: mat.define-light-theme(
    (
        color: (
            primary: mat.define-palette(axTheme.$axPalette),
            accent: mat.define-palette(axTheme.$axPalette),
            warn: null,
        ),

        typography: $typographyConfig,
        density: 0,
    )
);

// When using the above function "define-light-theme" to create a Material theme,
// the background and foreground colors are automatically set to $light-theme-foreground-palette
// and $light-theme-background-palette. To change e.g. the font color we need to override
// the variables defined there.
$material-theme-overrides: (
    'color': (
        'foreground': (
            // Change font color (e.g. dropdown items) to our dark grey
            // instead of Materials rgba('black', 0.87).
            'text': var(--font-dark-grey),
            'hint-text': var(--font-medium-grey),
            // <mat-hint> font color
            'secondary-text': var(--font-medium-grey),
            // Icon color
            'icon': var(--font-medium-grey),
            'icons': var(--font-medium-grey),
        ),
    ),
);

$theme: map.deep-merge($theme, $material-theme-overrides);

// Include all theme styles for the components.
@include mat.all-legacy-component-themes($theme);
/////////////////////////////////////////////////////////////////////////////*/
//  END autoiXpert Theme (blue)
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Qapter-iXpert Theme (orange)
//****************************************************************************/
html.qapterixpert {
    $primaryQapterixpert: mat.define-palette(axTheme.$qXPalette);
    $accentQapterixpert: mat.define-palette(axTheme.$qXPalette, A200, A100, A400);

    $themeQapterixpert: mat.define-light-theme(
        (
            color: (
                primary: $primaryQapterixpert,
                accent: $accentQapterixpert,
                warn: null,
            ),
        )
    );

    // - Include all theme styles for the components.
    // - Use all-legacy-component-colors instead of all-legacy-component-theme because the latter would generate CSS duplicates which render warnings in the compiler.
    @include mat.all-legacy-component-colors($themeQapterixpert);
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Qapter-iXpert Theme (orange)
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  aX Material Overwrites
//****************************************************************************/

/**
 * Create higher specificity than the html.qapterixpert prefix.
 */
html.autoixpert,
html.qapterixpert {
    /**
    * This file only contains rules that overwrite the default material defaults. Screen-specific styles
    * must either go into the component's scss or the styles.scss if styling in the component is not possible.
     */

    //*****************************************************************************
    //  Form Field
    //****************************************************************************/
    mat-form-field {
        // Overwrite Material Design's standard setting to Roboto and Helvetica Neue.
        font-family: inherit !important;
    }

    // This higher level of precision is required to overwrite the theme rules.
    .mat-form-field-appearance-legacy {
        .mat-form-field-underline,
        .mat-select-underline {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    .mat-form-field-infix {
        width: auto !important;
    }

    .mat-input-suffix {
        [matSuffix] {
            margin-left: 5px;
        }
    }

    [matSuffix] {
        margin-left: 4px;
    }

    // Ensure suffixes and prefixes of disabled fields are displayed in the same color as their text input value.
    .mat-form-field-disabled .mat-form-field-prefix,
    .mat-form-field-disabled .mat-form-field-suffix {
        color: inherit;
    }

    .mat-input-element:disabled {
        color: inherit;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
        // Center the text vertically. The original styling until Angular v15 is off.
        padding: 12px 0 16px 0;
    }

    .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
        transform: none;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: var(--primary-color);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Form Field
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  mat-select
    //****************************************************************************/
    mat-select {
        font-family: inherit !important;
    }

    // Overwrite the styling from @angular/material that limits the height of triggers. We need larger triggers for the fee set selection of a garage.
    div.mat-select-trigger {
        height: unset;
    }

    .mat-select-value {
        font-family: 'Source Sans Pro', sans-serif;
        color: #5c5c5c !important;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END mat-select
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  mat-menu
    //****************************************************************************/
    a[mat-menu-item] {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 15px;
    }

    div.mat-menu-panel {
        max-width: 400px;
    }

    .mat-menu-content-no-padding {
        .mat-menu-content {
            padding: 0 0 !important;
        }
    }

    .mat-menu-no-min-height {
        max-height: fit-content;
        min-height: 0 !important;
        .mat-menu-content {
            max-height: fit-content;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END mat-menu
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  mat-autocomplete
    //****************************************************************************/
    .mat-autocomplete-panel {
        &.min-width-autocomplete {
            min-width: 220px;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END mat-autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  mat-option
    //****************************************************************************/
    .mat-option {
        font-family: inherit;
        font-size: 15px;
    }

    .mat-option-text {
        max-width: 100%;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END mat-option
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  mat-checkbox
    //****************************************************************************/
    //*****************************************************************************
    //  Checkbox Disabled
    //****************************************************************************/

    .mat-checkbox-disabled {
        .mat-checkbox-label {
            color: inherit;
        }

        &.mat-checkbox-checked {
            .mat-checkbox-background {
                background: transparent;

                .mat-checkbox-checkmark {
                    .mat-checkbox-checkmark-path {
                        stroke: #448aff !important; // Material Theming uses !important, so this is necessary to overwrite.
                    }
                }
            }

            &:not(.mat-checkbox-indeterminate) {
                .mat-checkbox-frame {
                    border-color: #448aff;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Checkbox Disabled
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Checkbox Indeterminate
    //****************************************************************************/
    .mat-checkbox-indeterminate.mat-accent {
        .mat-checkbox-background {
            background: transparent;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Checkbox Indeterminate
    /////////////////////////////////////////////////////////////////////////////*/

    .mat-checkbox-label {
        white-space: normal;
        font-family: 'Source Sans Pro', Roboto, sans-serif;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END mat-checkbox
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltip
    //****************************************************************************/
    .mat-tooltip {
        font-size: 0.9rem !important;
        font-family: 'Source Sans Pro', sans-serif;
        overflow-wrap: break-word;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltip
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Datepicker
    //****************************************************************************/
    .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon.datepicker-toggle-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: #a1a1a1;
        opacity: 0.4;
        transition:
            color 0.2s ease,
            opacity 0.2s ease;

        &:hover {
            color: var(--primary-color);
            opacity: 1;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Datepicker
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog
    //****************************************************************************/
    .mat-dialog-title {
        font-family: 'Source Sans Pro', Roboto, 'Helvetica Neue', sans-serif;
        font-weight: 600;
        color: #5c5c5c;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mat Chip Input
    //****************************************************************************/
    .mat-chip-list-wrapper,
    autocomplete-bubble-list {
        input.mat-chip-input {
            // Make the input less likely to break to the next line
            width: 100%;
            flex: 1 1 100px;
        }

        .mat-standard-chip {
            border-radius: 5px;
            padding: 6px 10px 6px 10px;
            background: var(--light-element-background-color);
            color: #{darken(#5c5c5c, 5%)}; // A little darker than usual, so that the text is easier to read on light grey background
            font-size: inherit; // Use body font size so that it matches the remaining text on the page
            min-height: unset;
            height: auto;
            cursor: pointer;

            &.mat-chip-light {
                // A chip with a white background. Needed when the chip is placed inside a card that
                // has the same background color as the chip.
                background: white;
                border: 1px solid #{darken(#f4f6fa, 5%)}; // Color light-element-background-color -> a little darker
            }

            .mat-chip-ripple {
                // We don't want the ripple effect
                display: none;
            }

            &:not(.mat-chip-disabled):active {
                // No box shadow when selected
                box-shadow: none;
            }

            &:hover {
                // Don't change background color on hover, we have a cursor: pointer, that should be enough
                &::after {
                    opacity: 0;
                }
            }

            &:focus {
                // A little darker background indicates, that the chip is focused/selected
                &::after {
                    opacity: 0.1;
                }
            }

            &.mat-chip-with-trailing-icon {
                padding-right: 7px; // A little less spacing, because the close icon already has some whitespace to the right

                mat-icon {
                    font-size: 16px;
                    height: 16px;
                    width: 16px;
                    margin-left: 5px;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mat Chip Input
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mat Icon
    //****************************************************************************/
    .mat-icon {
        flex-shrink: 0;
        overflow: unset; // Remove the overflow: hidden from Angular's new default styles.
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mat Icon
    /////////////////////////////////////////////////////////////////////////////*/
}

/////////////////////////////////////////////////////////////////////////////*/
//  END aX Material Overwrites
/////////////////////////////////////////////////////////////////////////////*/
