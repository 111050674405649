/**
* Global variables used throughout the application
*/

// Height of the head runner (main title row)
$head-runner-height: 60px;

// Max height for full height dialogs (they leave a little space for the head runner + padding)
$dialog-container-max-height: calc(95vh - #{$head-runner-height}); // Compensate for the head runner of the app

// Linear gradient from bottom right (ax-blue) to upper left (lighter ax-blue)
$blueLinearGradientToUpperLeft: linear-gradient(-45deg, #15a9e8, #0adfff);
