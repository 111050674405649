/**
Bubble is used for almost all rich text fields in autoiXpert. This creates a tooltip-like toolbar that
only shows after the user selects text.
 */
.ql-bubble .ql-tooltip {
    /**
    Important so that the tooltip covers checkbox boxes. Seen in the AccidentDataComponent for field "Besichtigung" > "Bemerkung".
     */
    z-index: 1;

    /**
    Ensure the toolbar adheres to the autoiXpert styles.
     */
    border-radius: 4px;

    /**
    Don't show the arrow at the top or bottom of the toolbar. The arrow is sometimes rendered a little off, for example when
    selecting a full line an then an empty line beneath it. Since the corners of the toolbar box are rounded, the arrow
    causes a little space between the rounded corner --> looks ugly.
     */
    .ql-tooltip-arrow {
        display: none;
    }
}

.ql-toolbar.ql-snow {
    // Remove border to comply with autoiXpert's style.
    border: none;
    margin-left: -1px;
    background: #f4f6fa;
    margin-bottom: 10px;
    border-radius: 5px;
}

.ql-container.ql-snow {
    // Remove border to comply with autoiXpert's style.
    border: none;
}

.ql-container {
    /**
    Use autoiXpert's default font.
     */
    font-family: unset;
    font-size: unset;
}

.ql-editor {
    // Zero padding makes the editor look more like a textarea.
    padding: 0;
    // Ensure the line height is equal to the other inputs' line height.
    // Do not use "inherit" because that creates a scrollbar for a multi-line text if there is text on the last line.
    line-height: 1.25;

    // Makes sense in combination with mat-quill.component.scss > #editor's flex settings. This ensures the Quill contenteditable is as large as the quill container.
    // That's necessary so that the user sees a text selection cursor in all of a multi-line mat-quill if only the first line is filled.
    flex: 1;

    // The default Quill <p>, <ul> and <ol> styles are set so that they resemble margins in DOCX files.
    p:not(:first-child),
    ul:not(:first-child),
    ol:not(:first-child) {
        margin-top: 12px;
    }

    &.show-paragraph-marks {
        p::after {
            content: ' ¶';
        }

        /**
        Since the empty paragraphs have a content element at the end when .show-paragraph-marks is active, a break before the closing </p> tag would not become visible.
        That would be bad since it would double the size of <p><br></p> paragraphs. To prevent that, those breaks that are the last element in a paragraph are hidden.
        IMPORTANT: Determining the last child DOES include text nodes. Otherwise, the last break in a paragraph would be hidden although it's relevant.
         */
        p br:last-child {
            display: none;
        }
        // This does not work since <br>'s content is not displayed/cannot be styled: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/br#styling_with_css
        //br::after {
        //    content: ' ↵';
        //}
    }

    /**
    If this is an email editor or email templates display container, use styles more similar to Gmail.
    The default Quill styles are set so that they resemble margins in DOCX files.
     */
    &.ql-email {
        p:not(:first-child) {
            margin-top: 0;
        }
        ul:not(:first-child),
        ol:not(:first-child) {
            margin: 12px 0;
        }
        a[href] {
            color: #2861db;
        }
    }
}

/**
If .ql-editor is added to a DIV that's not editable (usually for preview purposes as in the text template selector), don't set
the cursor to "text". That ensures that cursor settings like "pointer" remain intact. That's more important so the user knows
the content is clickable instead of selectable.
 */
.ql-editor:not([contenteditable]) > * {
    cursor: inherit;
}

/**
Ensure that text template icons are displayed at the bottom of the mat-form-field. Since Quill does not have
a text baseline, the default mat-form-field setting "align-items: baseline" positions the icons at the top of
the Quill editor.
 */
.mat-form-field-type-mat-quill {
    .mat-form-field-flex {
        align-items: flex-end;
    }
    .mat-form-field-suffix {
        padding-bottom: 8px;
    }
}

//*****************************************************************************
//  Ordered & Unordered Lists (<ul> and <ol>)
//****************************************************************************/
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0;
    list-style-type: unset;
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 2.5em;
}

.ql-editor ol li:before,
.ql-editor ul li:before {
    content: unset;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Ordered & Unordered Lists (<ul> and <ol>)
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Right Click Table Menu (quill-better-table)
//****************************************************************************/
/**
This table plugin is currently (2021-09-29) disabled because it has too many issues:
- adding right column does not work, adding column to the left works
- splitting cells does not work
- ...
 */

.qlbt-operation-menu {
    background-color: #444;
    border-radius: 4px;
    color: #fff;

    /**
    The module "quill-better-table" has a JavaScript constant that sets the width to 200 px. We want a more flexible width to prevent line breaks.
     */
    width: auto !important;
}

.qlbt-operation-menu-item {
    margin: 8px 12px 8px;
    cursor: pointer;
    color: #ccc;
    display: flex;
    transition: color 0.2s;

    &:hover {
        color: #ffffff;
        path {
            fill: #ccc !important;
        }
    }

    .qlbt-operation-menu-icon {
        margin-right: 6px;
    }
    path {
        transition: fill 0.2s;
        fill: #5c5c5c !important;
    }
}

.qlbt-operation-menu-dividing {
    border-bottom: 1px solid #5c5c5c;
    margin: 10px;
}

/**
This column tool seems to be for the default quill toolbar on the bottom/top of the textarea. In autoiXpert,
however, we use the tooltip toolbar that floats. This qlbt-col-tool element creates an optical jump when
the user focuses the textarea.
 */
.qlbt-col-tool {
    display: none;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Right Click Table Menu (quill-better-table)
/////////////////////////////////////////////////////////////////////////////*/

/*****************************************************************************
/  Image Upload
/****************************************************************************/
.image-uploading {
    position: relative;
    display: inline-block;
}

.image-uploading img {
    max-width: 98% !important;
    filter: blur(5px);
    opacity: 0.3;
}

.image-uploading::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #1e986c;
    z-index: 1;
    animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

/*****************************************************************************
/ END Image Upload
/****************************************************************************/
